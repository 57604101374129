import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Button, Container } from 'reactstrap';
import Layout from '../../../components/Layout';
import SEO from '../../../components/Seo';
import Jumbotron from '../../../components/Jumbotron';
import { SECONDARY } from '../../../utils/colors';
import Thumb from '../../../assets/svgs/jobs.svg';

const Backend = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO title="Frontend Developer Sênior na Central do Frete" />
    <Jumbotron
      Image={Thumb}
      background={SECONDARY}
      title="Frontend Developer Sênior"
      subtitle="Venha ajudar a descomplicar a forma como as empresas contratam fretes"
    />
    <Container className="pb-5 pt-4">
      <h4>Prazer! Somos a Central do Frete</h4>
      <p>
        Nós somos uma startup de tecnologia que está transformando a maneira
        como as empresas contratam fretes.
      </p>
      <p>
        Em um mundo onde descomplicar é a palavra de ordem, chegamos para criar
        uma experiência mais satisfatória tanto para quem compra quanto para
        quem vende.
      </p>
      <h4>Local</h4>
      <p>
        Remoto: Todo o time de desenvolvimento trabalha remoto (Apenas a equipe
        de operação fica em São Caetano do Sul/SP)
      </p>
      <h4>Modalidade de contrato</h4>
      <p>Contrato PJ de prestação de serviços.</p>
      <h4>Requisitos Técnicos</h4>
      <ul>
        <li>Domínio da linguagem JavaScript;</li>
        <li>Sólida experiência com o framework ReactJS;</li>
        <li>Sólida experiência com HMTL/CSS;</li>
        <li>Experîencia Gatsby;</li>
        <li>Experiência com GraphQL;</li>
        <li>Competências em UX/UI;</li>
        <li>Alguma experiência com Docker;</li>
        <li>Lê e escreve em inglês;</li>
        <li>Experiência com Github.</li>
      </ul>
      <h4>O que brilharia aos nossos olhos</h4>
      <ul>
        <li>Ter disciplina;</li>
        <li>
          Ser analítico (atento a detalhes, organizado, focado, assertivo);
        </li>
        <li>Ser uma pessoa que está aberta a novas tecnologias;</li>
        <li>Ser adaptável, com facilidade de mudanças;</li>
        <li>
          Ter atitude de dono do negócio, ser despojado e ter jogo de cintura;
        </li>
        <li>Gostar de resolver problemas e ser colaborativo;</li>
        <li>Ser sociável e focado na experiência do cliente;</li>
        <li>Senso de responsabilidade e urgência;</li>
        <li>Experiência trabalhando com metodologias ágeis.</li>
      </ul>
      <h4>Sobre o processo seletivo</h4>
      <ol>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Comece a jornada:
          </h5>
          Para iniciar o seu processo, você faz sua inscrição exclusivamente
          através do botão increve-se logo abaixo.
        </li>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Mostre o que você sabe:
          </h5>
          Quase lá! Nesta etapa, você será desafiado a criar uma pequena
          interface para validar suas aptidões e competências. Se aprovado no
          desafio, você é selecionado para etapa final.
        </li>
        <li>
          <h5 style={{ fontSize: '1em', marginBottom: '4px' }}>
            Vamos nos conhecer melhor:
          </h5>
          Ufa! Mais um pouquinho… Nesta fase, os responsáveis da área que você
          irá trabalhar fazem uma entrevista com você remotamente, via whereby
          (antigo appearin). Se acreditarmos que você tem alinhamento com o
          time, você é contratado.
        </li>
      </ol>
      <footer className="pb-5">
        <Button
          size="lg"
          target="_blank"
          href="https://forms.gle/X9rKCRSCbjDKQSTb9"
        >
          Inscreva-se
        </Button>
        <Link className="ml-3" to="/vagas">
          Ver todas as vagas
        </Link>
      </footer>
    </Container>
  </Layout>
);

Backend.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Backend;
